import { defineStore } from "pinia";
import type { Facility, User } from "../types/index";

type productType = "kleanz" | "cams";

export const useAppStore = defineStore("app", () => {
  const globalNotification = useGlobalNotification();
  const loading = ref(false);
  const currentUser: Ref<User | null> = ref(null);
  const currentFacility: Ref<Facility | null> = ref(null);
  const product: productType = "kleanz";

  const productName = computed(() => (product === "kleanz" ? "Kleanz" : "Cams"));
  const productLogo = computed(() => (product === "kleanz" ? "/svg/logo.svg" : "/svg/logo-cams.svg"));

  const pageTitle = ref(productName.value);

  function startProcess(realWork: () => Promise<void>) {
    loading.value = true;
    realWork()
      .then(() => {
        devConsole.log("Work Done...");
      })
      .finally(() => {
        devConsole.log("Finally...");
        loading.value = false;
      });
  }

  function addError(error: string) {
    globalNotification.showError(error, false);
  }

  function clearErrors() {
    globalNotification.clearAllPersist(true);
  }

  return {
    loading,
    currentUser,
    currentFacility,
    startProcess,
    addError,
    clearErrors,
    product,
    productLogo,
    productName,
    pageTitle,
  };
});

export type AppStore = ReturnType<typeof useAppStore>;
